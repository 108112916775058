import React from "react"
import {Link} from "gatsby";
//Import the Header.css
import headerStyles from "./Header404.module.css";

const Header404 = (props) => (
<header className={headerStyles.header}>
    <div className={headerStyles.navContainer}>
        <div className={headerStyles.logoContainer}>
            <Link id="logo" to="/" title="Home Page"><img className={headerStyles.logo} src={'/logo.svg'} alt="Alessio Iervolino - logo" /></Link>
            <Link id="logo-dark" to="/" title="Home Page"><img className={headerStyles.logo} src={'/logo-white.svg'} alt="Alessio Iervolino - logo" /></Link>
        </div>
        <nav className={headerStyles.navigation}>{props.children}</nav>
    </div>
    <h1 className={headerStyles.heroTitle}>You have found the treasure!</h1>
    <h2 className={headerStyles.heroSubTitle}>...but not the page you were looking for.</h2>
    <Link to="/"><img className={headerStyles.heroImage} src={'/404.svg'} alt="Person with a treasure" /></Link>
</header>
)

export default Header404;