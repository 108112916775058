import React from "react"
/*import { Link } from "gatsby"*/
import SEO from "../components/seo"
import Header404 from "../components/Header404"
import {Link} from "gatsby";

const Page404 = () => (
  <>
  <SEO title="404 Page" />  
  <Header404>
    <ul>
      <li><Link to="/">About</Link></li>
      <li><Link to="/">Projects</Link></li>
      <li><Link to="/">History Facts</Link></li>
      <li><span id="theme-switcher"></span></li>
    </ul>
  </Header404>
  </>
)

export default Page404 

